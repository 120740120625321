<template>     
    <div class="container">   
        <div id="dspForm" class="loginPage">
            <div>
            <!-- <form autocomplete="off" @submit.prevent="loginSession">  -->
                <!-- <h1 style="font-size:50px;text-align: left;margin:0 0 30px 0px;">Sign In</h1>   -->
                <div class="lyt_div">
                    <label for="user" class="lyt_label" >Username</label>
                    <input id="user" class="lyt_input" type="text" v-model="loginData.username" placeholder="Enter your user name" required/>
                </div> 
                <div class="lyt_div">
                    <label for="pswd" class="lyt_label">Password</label>
                    <input id="pswd" class="lyt_input" :type="passwordFieldType" v-model="loginData.password" placeholder="Enter your password" required/>
                    <button id="pswStat" style="font-size:18px;height:45px;width:70px;margin-left:-40px;margin-top:12px;border-radius: 10px;" tabindex='-1' @click="togglePasswordVisibility">{{ toggleButtonText }}</button>
                </div> 
                <div style="color: red;text-align:left; margin-left: 27%;">
                    <p v-if="errMsg">{{ errMsg }}</p>
                    <p v-else >&nbsp;</p> 
                    <button @click="loginSession" class='btn' style="width:400px;">Login</button>
                </div>
                <p>
                    <span class="text-wrapper">By continuing, you agree to the </span>
                    <span class="span">Terms of use</span>
                    <span class="text-wrapper-2">&nbsp;</span>
                    <span class="text-wrapper">and</span>
                    <span class="text-wrapper-2">&nbsp;</span>
                    <span class="span">Privacy Policy.</span>
                    <span class="text-wrapper-3">&nbsp;</span>
                </p>
            <!-- </form> -->
            </div>
      </div>
    </div>
  </template>
  
  <script>
    import { useUserStore } from '../stores/userStore'
    import { strUTC } from '../util/util'

    export default {
        data() {
            return {
              loginData: {
                  username:'',
                  password: '',
                  loginTme:'',
                  secLevel:99
              },
              errMsg : '',
              isPasswordVisible: false,
              formTitle : ""
            }
        },
        components: {
            
        },
       
        created() {
            document.getElementById("formTitle").innerHTML = "Sign In";
        },

        mounted() {
            const curUser = useUserStore()
        },
        computed: {
            passwordFieldType() {
            return this.isPasswordVisible ? 'text' : 'password';
            },
            toggleButtonText() {
            return this.isPasswordVisible ? 'Hide' : 'Show';
            }
        },
        methods: {       
            togglePasswordVisibility() {
                this.isPasswordVisible = !this.isPasswordVisible;
            },   
            async loginSession() {
                const curUser = useUserStore()
                const host = curUser.apiServer
                this.loginData.loginTme=strUTC();
                // document.getElementById("errMsg").style.display = 'none';
                try {
                    const param = { method: 'POST',
                                    headers: {'Content-Type': 'application/json',
                                    'usr':this.loginData.username,
                                    'pwd':this.loginData.password,
                                    'tme':this.loginData.loginTme                               
                                }}
                    const url = host+'/login'                           
                    const response = await fetch(host+'/login', param);
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    const data = await response.json();
                    if (data.ok) {
                        if (data.secLevel==1) { 
                            curUser.setUser(this.loginData.username,data.cryptKey,data.secLevel,data.prtId)
                            this.$router.push({ name: 'TrxMgrApp'})
                        } else if (data.secLevel==2) {
                            curUser.setUser(this.loginData.username,data.cryptKey,data.secLevel,data.prtId)
                            this.$router.push({ name: 'TrxStfAmd'})
                        } else {
                            this.errMsg = 'You have no clearance to approve transaction'
                        }
                    } else {       
                        curUser.clearUser()
                        this.errMsg = 'Username/Password invalid'
                    }
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            }          
        }
    }
  
  
  </script>
  
  <style scoped>  
    .container {
        display: flex;
        justify-content: left; /* Centers child elements horizontally */
        align-items: top; /* Centers child elements vertically (optional) */
        height: 70vh; /* Adjust as needed to center within viewport */
    }

    .loginPage {
        margin: 100px 0 0 150px;
        width: 900px; /* Example width */
        height: 40vh; /* Example height */
        background-color: white;
        text-align: center; /* Optional: Centers text horizontally */
        line-height: 100px; /* Optional: Centers text vertically */
    }

    
    .lyt_div { 
    /* min-Width:500px; */
    float: none;
    line-height: 40px;
    height:80px;
    margin-left: 10px;
    }

    .lyt_label {
    float: left;
    line-height: 25px;
    text-align: left;
    font-size:25px;
    width: 20%;
    /* border-radius: 5px; */
    resize: none;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 0px;
    padding-bottom:5px;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 5px;
    }

    .lyt_input {
        float: left;
        line-height: 25px;
        font-size:25px;
        width: 60%;
        height:35px;
        padding-top: 5px;
        padding-right: 15px;
        padding-left: 10px;
        padding-bottom:5px;
        margin-top: 10px;  
        margin-bottom: 15px;
        margin-right: 20px;
        margin-left: 5px;
        border-radius: 5px;
        border: 1px solid black;
        /* text-transform:uppercase */
    }
    
    
    button {
        font-size:20px;
        color: white;
        background: #047857;
        margin: 0 10px;
        padding: 15px;
        border: none;
        border-radius: 20px;
    }

    .btn:focus {
    outline: 1px solid black;
    }

    .btn:hover {
    color: white;
    background-color: #47980E;
    background-image: linear-gradient(#ecf0f1 ,#47980E);
    }

    p {
        line-height: 28px;
        padding:0;
        margin-top:0px;
    }

  </style>