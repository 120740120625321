<template> 
  <div id="header" class="hdr" style="margin:25px 0 50px 0">
    <img alt="logo"  class="logo" src="./assets/logo.png">
    <!-- <P id="formTitle" style="font-size:50px;font-weight: bold;float: left;margin:0 0 0 100px;">Title</p> -->
    <h1 id="formTitle" style="font-size:50px;text-align: left;margin:20px 0px 0px 10px;">Sign In</h1>  
  </div>

  <router-view/>
</template>

<script>

export default {
        data() {
            return {
              formTitle : "Start"
            }
        }

      }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* 
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
