
export function dateYMD(strDate) {
    const d = new Date(strDate);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function strUTC() {
  const date = new Date(Date.now());
  const strDate = date.toISOString();
  return strDate
}

// export function strEncrypt() {
//   var crypto = require('crypto');

// var mykey = crypto.Cipher('aes-128-cbc', 'mypassword');
// var mystr = mykey.update('abc', 'utf8', 'hex')
// mystr += mykey.final('hex');

// czonsole.log(mystr); //34feb914c099df25794bf9ccb85bea72
// }