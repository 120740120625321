import { createRouter, createWebHashHistory } from 'vue-router'
import Login     from '../views/Login.vue'
import TrxStfAmd from '../views/TrxStfAmd.vue'
import TrxMgrApp from '../views/TrxMgrApp.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },

  {
    path: '/trxStfAmd',
    name: 'TrxStfAmd',
    component: TrxStfAmd
  },
  
  {
    path: '/trxMgrApp',
    name: 'TrxMgrApp',
    component: TrxMgrApp
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
